export interface IPhotoProps {
  id: number
  albumId: number
  isGroupPhoto: boolean
  logoCode?: 'not_set' | 'user_select' | 'always'
  not4captionFlag: boolean
  not4saleFlag: boolean
  not4trimFlag: boolean // デジブックの編集可否フラグ
  num: number
  pageOpenTs: string
  pagePublicCode: 'close' | 'hidden' | 'open' | 'error'
  photoHash: string
  photoUrl: {
    tn?: string
    tn2?: string
    w1?: string
    w2?: string
  }
  photographerId: number
  previewNo?: string
  typeCode?: 'snap' | 'group'
  visibleFlag: boolean
  visibleCode?: 'searchonly' | 'hidden' | 'visible' | 'invisible'
  moovinFlag: boolean
}

export default class PhotoEntity {
  private _props: IPhotoProps

  constructor(props: IPhotoProps) {
    this._props = props
  }

  get props(): IPhotoProps {
    return this._props
  }

  get photoNumber(): string {
    return this._props.previewNo ? 'No.' + this._props.previewNo : 'No.' + this._props.photographerId + '-' + this._props.num
  }

  get isEditable(): boolean {
    const { logoCode, not4captionFlag, not4trimFlag } = this._props

    return logoCode === 'user_select' || !not4captionFlag || !not4trimFlag
  }

  get originalLogoSettable(): boolean {
    return !!this._props.logoCode && this._props.logoCode === 'user_select'
  }
}

export const PhotoPropsFactory = (props?: Partial<IPhotoProps>): IPhotoProps => ({
  id: 1042686059,
  albumId: 10,
  isGroupPhoto: false,
  logoCode: 'not_set',
  not4captionFlag: false,
  not4saleFlag: false,
  not4trimFlag: false,
  num: 1418437,
  pageOpenTs: '2018-12-07 07:00:00.000000',
  pagePublicCode: 'open',
  photoHash: '0cef7313f75fcb8086af2a701fbc16e5',
  photoUrl: {
    tn: '/static/storybook/photo01.jpg',
    tn2: '/static/storybook/photo01.jpg',
    w1: '/static/storybook/photo01.jpg',
    w2: '/static/storybook/photo01.jpg'
  },
  photographerId: 3107,
  previewNo: '100',
  typeCode: 'snap',
  visibleFlag: false,
  visibleCode: 'visible',
  moovinFlag: false,
  ...props
})

export const PhotoEntityFactory = (props?: Partial<IPhotoProps>): PhotoEntity => {
  return new PhotoEntity(PhotoPropsFactory(props))
}
