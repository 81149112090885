








import Vue from 'vue'
import { Toast } from '@/types/domain/toast'
import ToastComponent from '@/components/shared/atoms/Toast'

interface IData {
  handle: NodeJS.Timeout | null
  unwait: (() => void) | null
  toast: Toast | null
  isShown: boolean
}

export default Vue.extend({
  components: {
    ToastComponent
  },
  props: {
    timeout: {
      type: Number,
      default: 3000
    },
    pause: {
      type: Number,
      default: 300
    }
  },
  data(): IData {
    return {
      handle: null,
      unwait: null,
      toast: null,
      isShown: false
    }
  },
  computed: {
    setClass(): string {
      return this.toast ? this.toast.type : ''
    }
  },
  watch: {
    isShown(newValue: boolean) {
      const toast = this.toast
      if (newValue === false && toast) {
        const toastIndex = this.$toastRepository.getQueues().findIndex(t => t.id === toast.id)
        if (toastIndex > -1) {
          this.$toastRepository.removeQueue(toastIndex)
        }
        this.toast = null
      }
    }
  },
  created() {
    this.showNextToast()
  },
  methods: {
    clearToastState() {
      if (this.handle) {
        clearTimeout(this.handle)
        this.handle = null
      }

      if (this.unwait) {
        this.unwait()
        this.unwait = null
      }
    },
    showNextToast() {
      this.clearToastState()
      const vm = this

      // A funciton to detect new item inside queues
      function condition() {
        return vm.$toastRepository.getQueues().find(toast => toast.shown === false)
      }

      // A watcher function to be called with new value
      function effect(toast: Toast) {
        if (!toast) return

        if (vm.handle) {
          clearTimeout(vm.handle)
          vm.handle = null
        }

        vm.unwait = null
        toast.shown = true
        vm.toast = toast
        vm.isShown = true
        vm.handle = setTimeout(() => {
          vm.isShown = false
          vm.handle = null
          vm.showNextToast()
        }, vm.timeout + vm.pause)
      }

      // @ts-ignore
      const unwait = this.$watch(condition, effect)
      this.unwait = unwait
    },
    onToastClose() {
      if (!this.isShown) return
      const vm = this
      this.clearToastState()
      this.isShown = false

      setTimeout(() => {
        vm.showNextToast()
      }, vm.pause)
    }
  }
})
